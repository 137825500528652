import { Container, Divider, Typography } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { GA_TRACKING_ID } from '../../lib/gtag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    left: 0,
    bottom: 0,
    width: "100%",
    overflow: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: "100%",
  },
}));

export default function Copyright() {
  const classes = useStyles();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GA_TRACKING_ID.length > 0) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', GA_TRACKING_ID);
      };
    }
  }, []);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Divider/>
      <Typography variant="body2" color="textSecondary" align="center">
        {" © ".concat(process.env.copyrightData)}
        {/* &nbsp; Designed & Developed by
        <Link color="inherit" href={`${process.env.copyright}`}>
          <a target="_blank"> Knowledge Broadband Services</a>
        </Link>{" "}
        {new Date().getFullYear()}
        {"."} */}
      </Typography>
      <Container style={{width: "100%", display: "flex", justifyContent: "center"}}> 
      {process.env.footerEspa ? 
        process.env.chamberName === 'messinia' ? 
        <img src= "/static/images/espa-messinia.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> : process.env.chamberName === 'arta' ? 
        <a href="https://www.e-artas.gr/banner-dimosiotitas/" target="_blank">
        <img src= "/static/images/espa-arta.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> </a>: process.env.chamberName === 'chios' ? 
        <img src= "/static/images/espa-chios.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> :  process.env.chamberName === 'helia' ? 
        <img src= "/static/images/espa-helia.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> : process.env.chamberName === 'samos' ? 
        <img src= "/static/images/espa-samos.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> : process.env.chamberName === 'trikala' ? 
        <img src= "/static/images/espa-trikala.jpg" alt='espa2021-2027 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        /> : 
         <img src= "/static/images/espa.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        />  
      : <></>
      }    
      </Container>
      <InputLabel align="center" className={classes.footerText}>
                        <a href="/TermsConditions" >
                              Όροι Χρήσης
                        </a>
                        {' '}|{' '}
                        <a href="/PrivacyPolicy" >
                            Πολιτική Ασφάλειας
                        </a>
      </InputLabel>

    </Container>
  );
}
